@import "~@fortum/elemental-ui/FortumSans.css";

body {
  font-family: FortumSans, sans-serif;
}

/* Ensure login form items are clearly visible in both light and dark modes */
button.MuiButton-outlined, /* Login button */
button.MuiButton-text, /* OK button */
input[placeholder="Email"], /* Email input */
input[placeholder="Password"] /* Password input */ {
  --fortum-light-grey: #f7f7f7;
  --fortum-professional-grey: #111111;

  border: 2px solid var(--fortum-professional-grey) !important;
  background: var(--fortum-light-grey) !important;
  color: var(--fortum-professional-grey) !important;
}

/* Hide unneeded markdown editor buttons */
.w-md-editor-toolbar button[data-name="strikethrough"],
.w-md-editor-toolbar button[data-name="quote"],
.w-md-editor-toolbar button[data-name="code"],
.w-md-editor-toolbar button[data-name="ordered-list"],
.w-md-editor-toolbar button[data-name="checked-list"],
.w-md-editor-toolbar button[data-name="preview"] {
  display: none;
}
